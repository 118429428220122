<template>
  <ul class="e-supporters">
    <li class="e-supporters__item e-supporters__item--BMFSFJ">
      <IconBMFSFJ class="e-supporters__logo BMFSFJ" />
    </li>
    <li v-if="showLogo" class="e-supporters__item e-supporters__item--DGFPI">
      <Logo class="e-supporters__logo DGFPI" />
    </li>
  </ul>
</template>

<script>
import IconBMFSFJ from 'assets/icons_orig/logo-BMFSFJ.svg'
import Logo from 'atoms/Logo/Logo'

export default {
  name: 'SupportersBar',
  components: {
    IconBMFSFJ,
    Logo
  },
  props: {
    showLogo: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss">
@import '_assets/supporters';
</style>

<template>
  <header class="e-header">
    <router-link
    :to="{name: userInfo && userInfo.id ? 'loginLanding' : 'index'}"
      v-if="counselingLogoLink && !loadLogoFailed"
      class="e-header__brand e-brand e-brand--custom"
    >
      <el-tooltip :content="counselingCenterName">
        <img
          class="e-brand__logo e-brand__logo--img"
          :class="`e-brand__logo--${aspectRatioFormat}`"
          :src="counselingLogoLink"
          @error="loadLogoFailed = true"
        />
      </el-tooltip>
    </router-link>
    <router-link v-else :to="{name: userInfo && userInfo.id ? 'loginLanding' : 'index'}" class="e-header__brand e-brand">
      <Logo class="e-brand__logo e-brand__logo--svg" />
      <component :is="logo" class="e-brand-secondary" />
    </router-link>
    <nav class="e-header__nav">
      <MetaNav />
    </nav>
    <div class="e-header__buttons">
      <template v-if="userInfo">
        <router-link
          v-if="$route.name === 'liveChat' || $route.name === 'chatDialogs'"
          :to="{
            name: 'dialogs'
          }"
          class="e-btn e-btn--primary e-header__button"
        >
          <IconEnvelope />
          <span class="e-mobile-hidden e-tablet-hidden">
            {{ $t('chat.emailSupport') }}
          </span>
        </router-link>

        <template v-else>
          <button
            v-if="!chatIsOpen"
            :disabled="!chatIsOpen"
            :aria-disabled="!chatIsOpen"
            class="e-btn is-disabled e-header__button"
          >
            <span class="e-mobile-hidden e-tablet-hidden">
              {{ $t('chat.navigation') }}
            </span>
            <chat-icon class="e-tablet-show" :show-notification="false" />
          </button>
          <router-link
            v-else
            :to="{
              name: 'liveChat'
            }"
            :class="[
              { 'needs-chat-interaction': newChatMessage },
              'e-btn',
              'e-btn--quaternary',
              'e-header__button'
            ]"
          >
            <chat-icon
              :show-notification="false"
              :notification-message="$t('chat.newMessage')"
            />
            <span class="e-mobile-hidden e-tablet-hidden">
              {{ $t('chat.navigation') }}
            </span>
          </router-link>
        </template>
      </template>
      <el-button
        name="logoutbutton"
        class="e-btn--alert e-header__button"
        @click="logoutAndCatchFire"
      >
        <IconExclamation />
        <span class="e-mobile-hidden e-tablet-hidden">
          {{ $t('system.panic') }}
        </span>
      </el-button>
    </div>


    <button
      v-if="!dialogOpen && !chatDialogOpen && !messageEditorOpen"
      type="button"
      name="offcanvas_toggle"
      class="e-offcanvas__toggle-header"
      @click="toggleOffcanvas"
    >
      <span />
      <span />
      <span />
    </button>
    <router-link
      v-if="
        !!chatDialogOpen &&
          ($route.name === 'chatDialogs' || $route.name === 'chatDialog')
      "
      :to="{ name: 'chatDialogs' }"
      class="e-header__toggle"
    >
      <IconChevronLeft />
    </router-link>
    <router-link
      v-if="
        !(!dialogOpen && !messageEditorOpen) &&
          ($route.name === 'dialogs' ||
            $route.name === 'dialog' ||
            $route.name === 'newDialog')
      "
      :to="{ name: 'dialogs' }"
      class="e-header__toggle"
    >
      <IconChevronLeft />
    </router-link>
    <router-link
      v-if="
        !(!dialogOpen && !messageEditorOpen) && $route.name === 'sentDialog'
      "
      :to="{ name: 'sent' }"
      class="e-header__toggle"
    >
      <IconChevronLeft />
    </router-link>
  </header>
</template>

<script>
import VueCookie from 'vue-cookie'
import { mapGetters, mapActions } from 'vuex'
import { userActions } from 'mixins'
import { counselingCenterLogoUrl } from 'api'

import Logo from 'atoms/Logo/Logo'
import Logopausentaste from 'atoms/Logo/LogoPausentaste'
import Logo1000grad from 'atoms/Logo/Logo1000grad'
import MetaNav from 'atoms/Metanav/Metanav'
import ChatIcon from 'atoms/ChatIcon/ChatIcon'

// Icons
import IconExclamation from 'assets/icons/basic-exclamation.svg'
import IconChevronLeft from 'assets/icons/chevron-left.svg'
import IconEnvelope from 'assets/icons/envelope.svg'

// Logos

export default {
  name: 'TheHeader',
  components: {
    IconExclamation,
    IconEnvelope,
    IconChevronLeft,
    MetaNav,
    Logo,
    Logopausentaste,
    Logo1000grad,
    ChatIcon
  },
  mixins: [userActions],
  data() {
    return {
      loadLogoFailed: false,
      aspectRatioFormat: ''
    }
  },
  computed: {
    ...mapGetters([
      'offcanvasOpen',
      'chatDialogOpen',
      'dialogOpen',
      'messageEditorOpen',
      'userInfo',
      'theme',
      'chatIsOpen',
      'newChatMessage',
      'counselingCenterId',
      'counselingCenters',
      'userInfo'
    ]),
    logo() {
      if (['pausentaste', '1000grad'].includes(this.theme)) {
        return 'Logo' + this.theme
      }
      return null
    },
    counselingLogoLink() {
      if (!this.counselingCenterId) {
        return null
      }
      let logoImage = new Image()
      logoImage.onload = () => {
        let height = logoImage.height
        let width = logoImage.width

        this.aspectRatioFormat = `default`
        if (height > width) {
          this.aspectRatioFormat = `hoch`
        }
        if (height < width) {
          this.aspectRatioFormat = `quer`
        }
        if (height === width) {
          this.aspectRatioFormat = `quadratisch`
        }
      }
      logoImage.src = counselingCenterLogoUrl(this.counselingCenterId)
      return counselingCenterLogoUrl(this.counselingCenterId)
    },
    counselingCenterName() {
      if (!this.counselingCenterId) {
        return ''
      }
      const center = this.counselingCenters
        .filter(
          counselingCenter => counselingCenter.id === this.counselingCenterId
        )
        .pop()
      return center && center.name ? center.name : ''
    }
  },
  watch: {
    counselingCenterId() {
      this.loadLogoFailed = false
    }
  },
  methods: {
    ...mapActions(['setOffcanvas', 'setDialog', 'storeDialog']),
    toggleOffcanvas() {
      this.setOffcanvas(!this.offcanvasOpen)
    },
    logoutAndCatchFire() {
      this.__user__logout(false)
      location.href = 'https://www.startpage.com'
    },
    isThemePausentasteActive() {
      return VueCookie.get('theme') && VueCookie.get('theme') === 'pausentaste'
    },
    isTheme1000GradActive() {
      return VueCookie.get('theme') && VueCookie.get('theme') === '1000grad'
    }
  }
}
</script>

<style lang="scss">
@import '_assets/header';
@import '_assets/headerBrand';
@import '_assets/headerButtons';
@import '_assets/headerNavigation';
@import '_assets/headerToggle';
</style>

<template>
  <img :src="logo" alt="dgfpi Onlineberatung" class="logo-img" />
</template>

<script>
export default {
  name: 'Logo',
  data: () => ({
    logo: require(`assets/bitmaps/logo-dgfpi.png`)
  })
}
</script>
<style lang="scss">
  .logo-img {
    display: block;
    //width: 132px;
    height: auto;
  }
</style>

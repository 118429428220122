<template>
  <footer class="e-footer">
    <span v-show="!hideLogos" class="e-brand">
      <Logo class="e-brand--white" />
      <LogoOrig class="e-brand--orig" />
    </span>
    <supporters
      class="e-footer__supporters"
      :show-logo="!!counselingCenterId"
    />
  </footer>
</template>

<script>
import Supporters from 'atoms/Supporters/Supporters'
import { mapGetters } from 'vuex'

// Icons
import Logo from 'assets/icons/logo-kinderschutz.svg'
import LogoOrig from 'assets/icons_orig/logo-kinderschutz.svg'

export default {
  name: 'TheFooter',
  components: {
    Logo,
    LogoOrig,
    Supporters
  },
  props: {
    hideLogos: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['counselingCenterId'])
  }
}
</script>

<style lang="scss" scoped>
@import '_assets/footer';
</style>
